import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { IonicModule, ModalController } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ServiceInjector } from './services/utils/injector.service';
import { stripeApiKey } from '../environments/environment';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './shared/store/user/user.effects';
import { BearerInterceptor } from './shared/interceptors/bearer.interceptor';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ApiModule } from './shared/openapi';
import { ConvertCaseInterceptor } from './shared/interceptors/convert-keys.interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UnauthorizedInterceptor } from './shared/interceptors/unauthorized.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandlerInterceptor } from '@app/shared/interceptors/error-handler.interceptor';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { authFeature } from '@app/shared/store/user/user.reducer';
import { ShowForRolePipe } from '@app/shared/store/user/utils/pipes/role.pipe';
import { NgxStripeModule } from 'ngx-stripe';
import { RouteReuseStrategy } from '@angular/router';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function playerFactory() {
  return player;
}

export class NoReuseStrategy implements RouteReuseStrategy {
  shouldDetach(): boolean {
    return false;
  }

  store(): void {}

  shouldAttach(): boolean {
    return false;
  }

  retrieve(): null {
    return null;
  }

  shouldReuseRoute(): boolean {
    return false;
  }
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollPadding: false,
      scrollAssist: false,
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    LottieModule.forRoot({ player: playerFactory }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    IonicStorageModule.forRoot(),
    ApiModule,
    ShowForRolePipe,
    NgxStripeModule.forRoot(stripeApiKey),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConvertCaseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    MessageService,
    {
      provide: RouteReuseStrategy,
      useClass: NoReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private injector: Injector,
    private modalCtrl: ModalController,
  ) {
    ServiceInjector.injector = this.injector;
    ServiceInjector.modalCtrl = this.modalCtrl;
  }
}
