/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { ConnectivityInDevice } from './connectivityInDevice';


export interface DeviceRead { 
    connection: ConnectivityInDevice;
    readonly createdAt?: string;
    description?: string | null;
    readonly icon?: string;
    readonly id?: string;
    readonly lastScan?: string;
    name: string;
    project: Project;
    type: string;
}

