/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConnectivityCreate { 
    alias?: string | null;
    description?: string | null;
    endpointId: string;
    endpointType: ConnectivityCreate.EndpointTypeEnum;
    readonly id?: string;
}
export namespace ConnectivityCreate {
    export type EndpointTypeEnum = 'simcard' | 'externalip' | 'vpn';
    export const EndpointTypeEnum = {
        Simcard: 'simcard' as EndpointTypeEnum,
        Externalip: 'externalip' as EndpointTypeEnum,
        Vpn: 'vpn' as EndpointTypeEnum
    };
}


