export * from './auth.service';
import { AuthService } from './auth.service';
import {apiConfig} from "@env/environment";
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './scan.service';
import { ScanService } from './scan.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './utility.service';
import { UtilityService } from './utility.service';
export * from './version.service';
import { VersionService } from './version.service';
export const APIS = [AuthService, PaymentService, ProjectService, ScanService, UserService, UtilityService, VersionService];
