import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defaultLanguage, supportedLanguages } from '@env/environment';
import { Device, GetLanguageCodeResult } from '@capacitor/device';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private defaultLanguage = defaultLanguage;
  private supportedLanguages: {
    text: string;
    value: string;
  }[] = supportedLanguages;
  private selected$: BehaviorSubject<string> = new BehaviorSubject<string>(defaultLanguage);

  constructor(private translate: TranslateService) {}

  async setInitialAppLanguage(): Promise<void> {
    const selected = await this.getCurrentDeviceLanguage();
    this.translate.setDefaultLang(selected);
    this.setLanguage(selected);
  }

  getLanguages(): { text: string; value: string }[] {
    return this.supportedLanguages;
  }

  setLanguage(langCode: string): void {
    this.translate.use(langCode);
    this.selected$.next(langCode);
  }

  async getCurrentDeviceLanguage(): Promise<string> {
    try {
      const language: GetLanguageCodeResult = await Device.getLanguageCode();
      language.value = language.value.split('-')[0].toLowerCase();
      language.value = language.value.length > 2 ? this.defaultLanguage : language.value;

      for (const lang of this.supportedLanguages) {
        if (lang.value === language.value) {
          return lang.value;
        }
      }
      return this.defaultLanguage;
    } catch (e) {
      return this.defaultLanguage;
    }
  }
}
