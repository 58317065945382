/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FindingTemplate { 
    /**
     * e.g COMX-0001
     */
    code: string;
    readonly createdAt?: string;
    cvssAttackVector?: string | null;
    cvssRisk: FindingTemplate.CvssRiskEnum;
    cvssScore?: number | null;
    cvssString?: string | null;
    /**
     * Is attached to the report along with the details of the particular finding
     */
    details?: string | null;
    readonly id?: string;
    impact?: FindingTemplate.ImpactEnum | null;
    likelihood?: FindingTemplate.LikelihoodEnum | null;
    overallRisk?: FindingTemplate.OverallRiskEnum | null;
    recommendations?: string | null;
    /**
     * Includes JSON object with optional keys \'links\', \'mapping\'.
     */
    references?: object | null;
    summary?: string | null;
    /**
     * e.g. Communication matrix created
     */
    title: string;
    readonly updatedAt?: string;
    version?: string;
}
export namespace FindingTemplate {
    export type CvssRiskEnum = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info' | 'Inconclusive';
    export const CvssRiskEnum = {
        Critical: 'Critical' as CvssRiskEnum,
        High: 'High' as CvssRiskEnum,
        Medium: 'Medium' as CvssRiskEnum,
        Low: 'Low' as CvssRiskEnum,
        Info: 'Info' as CvssRiskEnum,
        Inconclusive: 'Inconclusive' as CvssRiskEnum
    };
    export type ImpactEnum = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info' | 'Inconclusive';
    export const ImpactEnum = {
        Critical: 'Critical' as ImpactEnum,
        High: 'High' as ImpactEnum,
        Medium: 'Medium' as ImpactEnum,
        Low: 'Low' as ImpactEnum,
        Info: 'Info' as ImpactEnum,
        Inconclusive: 'Inconclusive' as ImpactEnum
    };
    export type LikelihoodEnum = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info' | 'Inconclusive';
    export const LikelihoodEnum = {
        Critical: 'Critical' as LikelihoodEnum,
        High: 'High' as LikelihoodEnum,
        Medium: 'Medium' as LikelihoodEnum,
        Low: 'Low' as LikelihoodEnum,
        Info: 'Info' as LikelihoodEnum,
        Inconclusive: 'Inconclusive' as LikelihoodEnum
    };
    export type OverallRiskEnum = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info' | 'Inconclusive';
    export const OverallRiskEnum = {
        Critical: 'Critical' as OverallRiskEnum,
        High: 'High' as OverallRiskEnum,
        Medium: 'Medium' as OverallRiskEnum,
        Low: 'Low' as OverallRiskEnum,
        Info: 'Info' as OverallRiskEnum,
        Inconclusive: 'Inconclusive' as OverallRiskEnum
    };
}


