/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { AccountReadMinimized } from './accountReadMinimized';
import { ListScanPlanType } from './listScanPlanType';
import { RetrieveScanPlanTagsInner } from './retrieveScanPlanTagsInner';
import { Finding } from './finding';
import { DeviceFirmwareList } from './deviceFirmwareList';
import { ScanPlanBTest } from './scanPlanBTest';
import { ScanPlanResult } from './scanPlanResult';
import { TrafficSegmentWithCurrentRetrieve } from './trafficSegmentWithCurrentRetrieve';


export interface RetrieveScanPlan { 
    readonly bTests?: Array<ScanPlanBTest>;
    createdBy: AccountReadMinimized;
    readonly devices?: string;
    duration?: string | null;
    finishedAt?: string | null;
    firmware: DeviceFirmwareList;
    readonly generalFindings?: Array<Finding>;
    readonly id?: string;
    isDownloadAvailable?: boolean;
    isPassed: boolean;
    project: Project;
    result: ScanPlanResult;
    readonly tags?: Array<RetrieveScanPlanTagsInner>;
    readonly totalRisk?: { [key: string]: string; };
    readonly trafficSegments?: Array<TrafficSegmentWithCurrentRetrieve>;
    type?: ListScanPlanType;
}

