/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateScanPlanFirmwareUpload } from './createScanPlanFirmwareUpload';
import { DeviceConnectivity } from './deviceConnectivity';
import { PacketFile } from './packetFile';
import { S3FileKey } from './s3FileKey';


export interface ScanPlanCreate { 
    bTests: Array<string>;
    devicesConnectivities: Array<DeviceConnectivity>;
    firmware?: CreateScanPlanFirmwareUpload;
    genericKeys?: Array<S3FileKey>;
    packetFiles?: Array<PacketFile>;
    project: string;
    type: string;
}

