/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceMinimized } from './deviceMinimized';
import { EndpointMinimized } from './endpointMinimized';


export interface ConnectivityList { 
    alias?: string | null;
    device: DeviceMinimized;
    endpoint?: EndpointMinimized;
    readonly id?: string;
    inUse: boolean;
    lastTimeUsed?: string | null;
}

