/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { ListScanPlanType } from './listScanPlanType';
import { ScanPlanResult } from './scanPlanResult';


export interface ListScanPlan { 
    readonly devices?: string;
    finishedAt?: string | null;
    readonly id?: string;
    isDownloadAvailable?: boolean;
    isPassed: boolean;
    project: Project;
    result: ScanPlanResult;
    type?: ListScanPlanType;
}

