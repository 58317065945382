/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FindingTemplate } from './findingTemplate';


export interface Finding { 
    details?: string | null;
    readonly id?: string;
    location: string;
    positive: boolean;
    template?: FindingTemplate;
    type?: Finding.TypeEnum;
}
export namespace Finding {
    export type TypeEnum = 'DEVICE_BASED' | 'GENERAL';
    export const TypeEnum = {
        DeviceBased: 'DEVICE_BASED' as TypeEnum,
        General: 'GENERAL' as TypeEnum
    };
}


