import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

const Storage = Preferences;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public async set<T>(key: string, value: T): Promise<void> {
    await Storage.set({
      key,
      value: JSON.stringify(value),
    });
  }

  public async get<T>(key: string): Promise<T | undefined> {
    const { value } = await Storage.get({ key });
    return value ? (JSON.parse(value) as T) : undefined;
  }

  public async remove(key: string): Promise<void> {
    await Storage.remove({ key });
  }

  public async keys(): Promise<string[]> {
    const { keys } = await Storage.keys();
    return keys;
  }

  public async clear(): Promise<void> {
    await Storage.clear();
  }
}
